

export const PolicyConfig = [
    {
      title: 'Use of your Personal Information',
      description: 'Bttrades collects and uses your personal information to operate and deliver the services you have requested. Bttrades may also use your personally identifiable information to inform you of other products or services available from Bttrades and its affiliate',
    },
    {
        title: 'Sharing Information with Third Parties',
        description: 'Bttrades may sell, rent, or lease customer information to third parties for the following reasons: We will collect this data and provide it to third parties if it is a necessary step in the User account creation process, contributes to the ease of use, reliability, or functionality of our products, or if it is of mutual interest to Bttrades. Bttrades may, from time to time, contact you on behalf of external business partners about a particular offering that may be of interest to you. In those cases, your unique personally identifiable information (e-mail, name, address, telephone number) is transferred to the third party. Bttrades may share data with trusted partners to help perform statistical analysis, send you email or postal mail, provide customer support, or arrange for deliveries. All such third parties are prohibited from using your personal information except to provide these services to Bttrades, and they are required to maintain the confidentiality of your information. Bttrades may disclose your personal information, without notice, if required to do so by law or in the good faith belief that such action is necessary to: (a) conform to the edicts of the law or comply with legal process served on Bttrades or the Site; (b) protect and defend the rights or property of Bttrades; and/or (c) act under exigent circumstances to protect the personal safety of users of Bttrades, or the public.',
    },
    {
        title: 'Tracking User Behavior' ,
        description: "Bttrades may keep track of the websites and pages our users visit within Bttrades, in order to determine what Bttrades services are the most popular. This data is used to deliver customized content and advertising within Bttrades to customers whose behavior indicates that they are interested in a particular subject area.",
    },
    {
      title: 'Automatically Collected Information' ,
      description: 'Information about your computer hardware and software may be automatically collected by Bttrades. This information can include your IP address, browser type, domain names, access times, and referring website addresses. This information is used for the operation of Page 3 of 5 services, to maintain the quality of the service, and to provide general statistics regarding the use of the Bttrades website.',
    },
    {
     title: 'Use of Cookies' ,
     description: 'Bttrades website may use cookies to help you personalize your online experience. A cookie is a text file that is placed on your hard disk by a web page server. Cookies cannot be used to run programs or deliver viruses to your computer. Cookies are uniquely assigned to you and can only be read by a web server in the domain that issued the cookie to you. One of the primary purposes of cookies is to provide a convenience feature to save you time. The purpose of a cookie is to tell the Web server that you have returned to a specific page. For example, if you personalize Bttrades pages, or register with Bttrades site or services, a cookie helps Bttrades to recall your specific information on subsequent visits. This simplifies the process of recording your personal information, such as billing addresses, shipping addresses, and so on. When you return to the same Bttrades website, the information you previously provided can be retrieved, so you can easily use Bttrades features that you customized. You have the ability to accept or decline cookies. Most Web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. If you choose to decline cookies, you may not be able to fully experience the interactive features of Bttrades services or websites you visit.',
    },
    {
        title: 'Links' ,
        description: 'This website contains links to other sites. Please be aware that we are not responsible for the content or privacy practices of such other sites. We encourage our users to be aware when they leave our site and to read the privacy statements of any other site that collects personally identifiable information.',
    },
    {
        title: 'Security of your Personal Information' ,
        description: 'Bttrades secures your personal information from unauthorized access, use, or disclosure. Bttrades uses the following methods for this purpose:',
    },
    {
        title: 'SSL Protocol' ,
        description: 'When personal information (such as a credit card number) is transmitted to other websites, it is protected through the use of encryption, such as the Secure Sockets Layer (SSL) protocol. We strive to take appropriate security measures to protect against unauthorized access to or alteration of your personal information. Unfortunately, no data transmission over the Internet or any wireless network can be guaranteed to be 100% secure. As a result, while we strive to protect your personal information, you acknowledge that: (a) there are security and privacy limitations inherent to the Internet which are beyond our control; and (b) security, integrity, and privacy of any and all information and data exchanged between you and us through this Site cannot be guaranteed.',
    },
    {
        title: 'Children Under Thirteen' ,
        description: 'Bttrades does not knowingly collect personally identifiable information from children under the age of thirteen. If you are under the age of thirteen, you must ask your parent or guardian for permission to use this website.',
    },
    {
        title: 'External Data Storage' ,
        description: 'We may store your data on servers provided by third-party hosting vendors with whom we have contracted.',
    },
    {
        title: 'Changes to this Statement' ,
        description: 'Bttrades reserves the right to change this Privacy Policy from time to time. We will notify you about significant changes in the way we treat personal information by sending a notice to the primary email address specified in your account, placing a prominent notice on our website, and/or updating any privacy information. Your continued use of the website and/or Services available after such modifications will constitute your: (a) acknowledgment of the modified Privacy Policy; and (b) agreement to abide and be bound by that Policy',
    },
    {
        title: 'Contact Information' ,
        description: 'Bttrades welcomes your questions or comments regarding this Statement of Privacy. If you believe that Bttrades has not adhered to this Statement, please contact HBttrade at support@fundingpips.com This statement is effective as of November 1st, 2021.',
    },
  ];
  